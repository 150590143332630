import React from "react";
import { Menu, Layout, theme } from "antd";
import { useAPI } from "src/components/AmazonPayFAQ/APIContext";
import Error404 from "src/components/Error404";
import "../FAQ.css";
import { FAQ_BASEURL } from "src/appConstants";

const { Sider } = Layout;
const { SubMenu } = Menu;

const FaqSidebar = ({ nonSpPage, sideBarSelectedCategory }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const contextResult = useAPI();
  if (contextResult) {
    const {
      registrationFAQ,
      integrationFAQ,
      operationFAQ,
      sellerCentralFAQ,
      buyerFAQ,
      spFAQ,
    } = contextResult;
    const faqs = [
      registrationFAQ,
      integrationFAQ,
      operationFAQ,
      sellerCentralFAQ,
      buyerFAQ,
    ];
    const SP = "sp";
    const groupedBySpCategory = [];
    spFAQ.forEach((spFAQ) => {
      const category = spFAQ.Category;
      groupedBySpCategory[category] = groupedBySpCategory[category] || [];
      groupedBySpCategory[category].push(spFAQ);
    });
    const GroupedSpFAQ = Object.values(groupedBySpCategory);
    const filterFaqBasedSubcategory = (FAQ, category, subCategory) => {
      sideBarSelectedCategory(
        FAQ.filter((row) => row.Subcategory === subCategory).map((faq) => {
          return faq;
        }),
        subCategory,
        [category, subCategory],
      );
    };
    return (
      <Sider
        width={200}
        style={{
          background: colorBgContainer,
          display: "flex",
          alignItems: "left",
          overflow: "auto",
        }}
      >
        <Menu style={{ width: 200 }} mode="vertical">
          {(nonSpPage ? faqs : GroupedSpFAQ).map((faq) => {
            return (
              <SubMenu
                title={faq[0].Category.replace(/\b\w/g, (x) => x.toUpperCase())}
                onTitleClick={() => {
                  window.history.replaceState(
                    null,
                    "",
                    FAQ_BASEURL + "/" + (nonSpPage ? faq[0].Category : SP),
                  );
                  sideBarSelectedCategory(faq, faq[0].Category, [
                    faq[0].Category,
                  ]);
                }}
                key={faq[0].Category}
                className="text-primary"
              >
                {[
                  ...new Set(
                    faq.map((faq) => {
                      return faq.Subcategory;
                    }),
                  ),
                ].map((subCategory) => {
                  return (
                    <Menu.Item
                      className="text-primary"
                      onClick={() => {
                        window.history.replaceState(
                          null,
                          "",
                          FAQ_BASEURL +
                            "/" +
                            (nonSpPage ? faq[0].Category : SP),
                        );
                        filterFaqBasedSubcategory(
                          faq,
                          faq[0].Category,
                          subCategory,
                        );
                      }}
                    >
                      {subCategory.replace(/\b\w/g, (x) => x.toUpperCase())}
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          })}
        </Menu>
      </Sider>
    );
  } else {
    return <Error404 linkTo="/technical-faq"/>;
  }
};

export default FaqSidebar;
