import React, { useContext, useState, useEffect, createContext } from "react";
import { FAQ_CONTENT_CLOUDFRONT_URL } from "src/appConstants";
import axios from "axios";
import { FaqElement } from "../AmazonPayFAQ/Types/FAQData";
import swal from "sweetalert";

export type FaqContextType = {
  registrationFAQ: FaqElement[];
  integrationFAQ: FaqElement[];
  operationFAQ: FaqElement[];
  sellerCentralFAQ: FaqElement[];
  buyerFAQ: FaqElement[];
  spFAQ: FaqElement[];
  totalFAQ: FaqElement[];
};

const APIContext = createContext<FaqContextType | null>(null);

export function APIContextProvider({ children }) {
  const [registrationFAQ, setRegistrationFAQ] = useState<FaqElement[] | null>(
    null,
  );
  const [operationFAQ, setOperationFAQ] = useState<FaqElement[] | null>(null);
  const [integrationFAQ, setIntegrationFAQ] = useState<FaqElement[] | null>(
    null,
  );
  const [sellerCentralFAQ, setSellerCentralFAQ] = useState<FaqElement[] | null>(
    null,
  );
  const [buyerFAQ, setBuyerFAQ] = useState<FaqElement[] | null>(null);
  const [spFAQ, setSpFAQ] = useState<FaqElement[] | null>(null);
  useEffect(() => {
    axios
      .get(FAQ_CONTENT_CLOUDFRONT_URL + "RegistrationFAQ.json")
      .then((res) => {
        setRegistrationFAQ(res.data);
      })
      .catch((error) => {
        errorHandler(error);
      });

    axios
      .get(FAQ_CONTENT_CLOUDFRONT_URL + "IntegrationFAQ.json")
      .then((res) => {
        setIntegrationFAQ(res.data);
      })
      .catch((error) => {
        errorHandler(error);
      });

    axios
      .get(FAQ_CONTENT_CLOUDFRONT_URL + "OperationFAQ.json")
      .then((res) => {
        setOperationFAQ(res.data);
      })
      .catch((error) => {
        errorHandler(error);
      });

    axios
      .get(FAQ_CONTENT_CLOUDFRONT_URL + "SellerCentralFAQ.json")
      .then((res) => {
        setSellerCentralFAQ(res.data);
      })
      .catch((error) => {
        errorHandler(error);
      });

    axios
      .get(FAQ_CONTENT_CLOUDFRONT_URL + "BuyerFAQ.json")
      .then((res) => {
        setBuyerFAQ(res.data);
      })
      .catch((error) => {
        errorHandler(error);
      });

    axios
      .get(FAQ_CONTENT_CLOUDFRONT_URL + "spFAQ.json")
      .then((res) => {
        setSpFAQ(res.data);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }, []);
  function ReturnData() {
    if (
      registrationFAQ != null &&
      operationFAQ != null &&
      integrationFAQ != null &&
      sellerCentralFAQ != null &&
      buyerFAQ != null &&
      spFAQ != null
    ) {
      let totalFAQ = registrationFAQ
        .concat(operationFAQ)
        .concat(integrationFAQ)
        .concat(sellerCentralFAQ)
        .concat(buyerFAQ);
      return (
        <APIContext.Provider
          value={{
            registrationFAQ,
            integrationFAQ,
            operationFAQ,
            sellerCentralFAQ,
            buyerFAQ,
            spFAQ,
            totalFAQ,
          }}
        >
          {children}
        </APIContext.Provider>
      );
    } else {
      return null;
    }
  }
  function errorHandler(error) {
    console.log("error " + error);
    swal(
      "Oops!",
      "Something went wrong. Please clear your browser cache and try again.",
      "error",
    );
  }
  return ReturnData();
}

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
