import { StepProps } from 'antd';

export const integrationUseCases = [
  {
    type: "Button Appearance",
  },
  {
    type: "Hosted Page",
  },
  {
    type: "Review Page",
  },
  {
    type: "Checkout Page",
  },
  {
    type: "Multi-Language",
  },
  {
    type: "Multi-Currency",
  },
  {
    type: "Address Restrictions",
  },
  {
    type: "Digital Products",
  },
  {
    type: "Physical Products",
  },
  {
    type: "Recurring Payments",
  },
  {
    type: "APB",
  },
  {
    type: "Authorize with Capture",
  },
  {
    type: "Authorize",
  },
  {
    type: "Confirm",
  },
  {
    type: "Asynchronous Processing",
  },
  {
    type: "Refund",
  },
  {
    type: "Order Cancellation",
  },
  {
    type: "IPN",
  },
  {
    type: "Solution Provider",
  },
  {
    type: "E-mails",
  },
  {
    type: "Billing Address Required",
  },
  {
    type: "Apply Promotions/ Gift Card",
  },
  {
    type: "Add Shipping Cost",
  },
  {
    type: "Shipment with Supported Carrier",
  },
  {
    type: "Split Ship Payment and Capture",
  },
];

export const locale = ["US", "EU", "JP"];

const API_GATEWAY_URL_DEVO = `https://xbpw2cxiwb.execute-api.us-east-1.amazonaws.com`;
const API_GATEWAY_URL_PROD = `https://2diqn02qnl.execute-api.us-east-1.amazonaws.com`;
const API_GATEWAY_PATH = "/prod/getTestCases?payload=";
const FAQ_CONTENT_CLOUDFRONT_URL_PROD =
  "https://d12e4f60883igw.cloudfront.net/";
const FAQ_CONTENT_CLOUDFRONT_URL_DEVO =
  "https://d18dpbo5srvkdx.cloudfront.net/";
const FAQ_ASSEST_CLOUDFRONT_URL_PROD = "https://d3vui5lry05h3f.cloudfront.net/";
const FAQ_ASSEST_CLOUDFRONT_URL_DEVO = "https://d5ucaq65bqmsu.cloudfront.net/";

export const API_GATEWAY_URL =
  process.env.NODE_ENV === "production"
    ? API_GATEWAY_URL_PROD + API_GATEWAY_PATH
    : API_GATEWAY_URL_DEVO + API_GATEWAY_PATH;
export const FAQ_CONTENT_CLOUDFRONT_URL =
  process.env.NODE_ENV === "production"
    ? FAQ_CONTENT_CLOUDFRONT_URL_PROD
    : FAQ_CONTENT_CLOUDFRONT_URL_DEVO;
export const FAQ_ASSEST_CLOUDFRONT_URL =
  process.env.NODE_ENV === "production"
    ? FAQ_ASSEST_CLOUDFRONT_URL_PROD
    : FAQ_ASSEST_CLOUDFRONT_URL_DEVO;

export const AMAZONPAY_LOGO_URL = "https://m.media-amazon.com/images/G/01/AmazonPay/landing/03/2017/amazon-pay-logo-light._V530839464_.png";
export const FAQ_BASEURL = window.location.origin + "/technical-faq";

const INTEGRIFY_API_GATEWAY_URLS = {
  dev: 'https://aus1n89apa.execute-api.us-east-1.amazonaws.com',
  prod: 'https://yjm81lq0ml.execute-api.us-east-1.amazonaws.com',
};

const INTEGRIFY_API_GATEWAY_PATH = '/prod/generateCustomIntegrationGuide/test';

export const INTEGRIFY_API_GATEWAY_URL = `${process.env.NODE_ENV === 'production' ? INTEGRIFY_API_GATEWAY_URLS.prod : INTEGRIFY_API_GATEWAY_URLS.dev}${INTEGRIFY_API_GATEWAY_PATH}`;

export const INTEGRIFY_TOOL_QUESTIONS = [
  { key: 'region', label: 'In which region do you wish to integrate?', options: ['US', 'EU', 'UK', 'JP'] },
  { key: 'chargePermissionType', label: 'What type of payment options are you looking for?', options: ['One-Time Payments', 'Recurring Payments']},
  { key: 'buttonPlacement', label: 'Where do you want to place the Amazon Pay button on your website?', options: ['Cart', 'Home', 'Product', 'Checkout', 'Other']},
  { key: 'productType', label: 'What type of products do you sell?', options: ['Physical', 'Digital']},
  { key: 'paymentIntent', label: 'When do you charge the buyer when placing an order?', options: ['During placing order', 'Authorize during placing order, Capture at the time of shipment', 'At the time of shipment']},
  { key: 'SDKLanguage', label: 'SDKs make it easy to integrate with Amazon Pay. We offer SDKs in 4 languages. Which language is compatible for you?', options: ['PHP', 'Java','Node.js', '.NET', 'None of these']},
  { key: 'async', label: 'Do you support async transactions?', options: ['Yes', 'No']},
  { key: 'addressRestrictions', label: 'Does your store have any address restrictions?', options: ['Yes', 'No'] },
  { key: 'multiShipments', label: 'Does your store support multi-shipments?', options: ['Yes', 'No']},
  { key: 'signIn', label: 'Do you have a Sign-In page on your website?', options: ['Yes', 'No']},
];

export const INTEGRIFY_TOOL_SUB_QUESTIONS = [
  { key: 'multiCurrency', label: 'Do you support multi-currency?', options: ['Yes', 'No'] },
  { key: 'multiLanguage', label: 'Do your website support multiple languages?', options: ['Yes', 'No'] },
];

export const INTEGRIFY_TOOL_STEPS: StepProps[] = [
  {
    title: 'Step 1',
    description: 'Select the features you can implement on your website in the form below.',
    status: 'process'
  },
  {
    title: 'Step 2',
    description: 'Submit the form.',
    status: 'process'
  },
  {
    title: 'Step 3',
    description: 'Get a link to a guide tailored for your integration with detailed steps, code snippets, and recommended configurations.',
    status: 'process'
  }
];

