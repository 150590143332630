import React, { useRef, useState } from "react";
import parse from "html-react-parser";
import Accordion from "react-bootstrap/Accordion";
import { Paginator } from "primereact/paginator";
import { LinkOutlined } from "@ant-design/icons";
import { Toast } from "primereact/toast";
import { FAQ_BASEURL } from "src/appConstants";
const FaqDisplay = (props) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * rows;
  const endIndex = startIndex + rows;
  const subset = props.FAQ.slice(startIndex, endIndex);
  const toast = useRef<any>();

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setCurrentPage(event.page);
  };
  const onLinkClick = (QuestionId, Category) => {
    navigator.clipboard
      .writeText(
        FAQ_BASEURL +
          "/" +
          (props.nonSpPage ? Category : "sp") +
          "#" +
          QuestionId,
      )
      .then(showSuccess);
    return undefined;
  };
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Copied URL",
      life: 1000,
    });
  };
  return (
    <>
      <Accordion flush className="mt-5">
        {subset.map((faq) => {
          return (
            <Accordion.Item eventKey={faq.QuestionId}>
              <Accordion.Header className="fs-3 fw-bold">
                {
                  <>
                    <LinkOutlined
                      rev={undefined}
                      onClick={() => {
                        onLinkClick(faq.QuestionId, faq.Category);
                      }}
                    />
                    &nbsp; <b>{faq.Question}</b>
                  </>
                }
              </Accordion.Header>
              <Accordion.Body className="text-start fs-6 bg-light shadow-5 text-dark">
                {parse(faq.Answer.replace(/technical-faq/g, FAQ_BASEURL))}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <div className="card">
        <Paginator
          first={first}
          rows={rows}
          totalRecords={props.FAQ.length}
          rowsPerPageOptions={[10, 20, 30, 50]}
          onPageChange={onPageChange}
        />
      </div>
      <Toast ref={toast} position="top-center" />
    </>
  );
};

export default FaqDisplay;
