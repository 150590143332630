import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Error404 = ({ linkTo}) => {
  return (
    <ErrorMessage>
      Page Not Found
      <br />
      <br />
      <Link to={linkTo}>Go Back to Home Page</Link>
    </ErrorMessage>
  );
};

const ErrorMessage = styled.div`
  height: 80vh;
  padding-top: 70px;
  font-size: 30px;
  text-align: center;
`;

export default Error404;