import React, { useState } from 'react';
import axios from 'axios';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { API_GATEWAY_URL, locale, integrationUseCases } from '../appConstants';
import { columns, ExpandedComponent, customStyles } from './Table';
const UATForm = () => {
  const [checkedState, setCheckedState] = useState(
    new Array(integrationUseCases.length).fill(false)
  );
  const [table, setTable] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOnChange = (position: any) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const checkedCases = integrationUseCases.filter((item, index) => checkedState[index] === true);

    const useCases = checkedCases.map((element) => element.type);
    axios
      .get(`${API_GATEWAY_URL}${useCases}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: any) => {
        let data = [...new Map(response['data'].map((item) => [item['TestID'], item])).values()];
        setTable({ columns, data });
        setLoading(false);
        setShowTable(true);
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };
  return (
    <>
      <Form className="form" onSubmit={handleSubmit}>
        <h3>First things first, Please enter basic details:</h3>
        <hr />
        <Row>
          <Col>
            <Form.Control type="text" placeholder="Merchant Name" />
          </Col>
          <Col>
            <Form.Select>
              <option defaultValue="" disabled selected>
                Locale
              </option>
              {locale.map((type) => {
                return <option>{type}</option>;
              })}
            </Form.Select>
          </Col>
        </Row>
        <br />
        <br />
        <h3>Choose the type of tests you need to run on your website:</h3>
        <hr />
        <Row>
          <ul className="use-cases-list">
            {integrationUseCases.map(({ type }, index) => {
              return (
                <li className="use-cases-list-item" key={index}>
                  <Form.Check.Input
                    id={`custom-checkbox-${index}`}
                    name={type}
                    checked={checkedState[index]}
                    onChange={() => handleOnChange(index)}
                  />
                  &nbsp;
                  <Form.Check.Label htmlFor={`custom-checkbox-${index}`}>{type}</Form.Check.Label>
                </li>
              );
            })}
          </ul>
        </Row>
        <br />
        <Button type="submit" variant="primary">
          Get Test Cases!
        </Button>
      </Form>
      <br />
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <br />
      )}
      {showTable ? (
        <div className="test-case-table">
          <DataTableExtensions exportHeaders {...table}>
            <DataTable
              columns={columns}
              data={table[1]}
              noHeader
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              expandableRows={true}
              expandOnRowClicked={true}
              expandableRowsComponent={<ExpandedComponent data={table[1]} />}
              customStyles={customStyles}
            />
          </DataTableExtensions>
        </div>
      ) : null}
    </>
  );
};
export default UATForm;
