import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import './logger';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-data-table-component-extensions/dist/index.css';
import './index.scss';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
    <React.StrictMode>
        <AppInitWrapper>
            <React.Suspense fallback={<KatSpinner size="large" />}>
                <App />
            </React.Suspense>
        </AppInitWrapper>
    </React.StrictMode>,
    document.getElementById('root')
);
