import React, { useState } from 'react';
import { Form, Radio, Button, Spin, Result, Card, Tooltip, Typography } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import axios from 'axios';

import { INTEGRIFY_API_GATEWAY_URL, INTEGRIFY_TOOL_QUESTIONS, INTEGRIFY_TOOL_SUB_QUESTIONS } from '../../appConstants';
import './Integrify.css';



const IntegrifyForm = () => {
  const [answers, setAnswers] = useState<{ [key: string]: string | null }>({
    region: null,
    chargePermissionType: null,
    buttonPlacement: null,
    productType: null,
    paymentIntent: null,
    SDKLanguage: null,
    async: null,
    addressRestrictions: null,
    multiShipments: null,
    signIn: null,
    multiCurrency: null, 
    multiLanguage: null, 
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const handleAnswerChange = (key: string, value: string) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [key]: value
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    const params = { ...answers };
    axios
      .get(`${INTEGRIFY_API_GATEWAY_URL}`, { params })
      .then((response: any) => {
        setUrl(response.data);
        console.log(response);
        setLoading(false);
        setShowResult(true);
        
      })
      .catch((error) => {
        console.log('Exception while fetching from API Gateway:' + error);
      });
  };

  return (
    <div>
      <Card className='integrifyForm' title={<span className="cardTitle">Fill out the form below. Your response will help us customize the guide with relevant instructions and best practices.</span>} hoverable>
        <Form className="formContent" layout='vertical'>
          {INTEGRIFY_TOOL_QUESTIONS.map(question => (
            <Form.Item key={question.key} name={question.key} label={<span className="formContent">{question.label}</span>}>
              <Radio.Group
                onChange={(e: RadioChangeEvent) => handleAnswerChange(question.key, e.target.value)}
                value={answers[question.key]}
              >
                {question.options.map(option => (
                  <Radio.Button key={option} value={option} className='formRadioButtons'>{option}</Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          ))}
          {(answers['region'] === 'EU' || answers['region'] === 'UK') && (
            <>
              {INTEGRIFY_TOOL_SUB_QUESTIONS.map(subQuestion => (
                <Form.Item key={subQuestion.key} name={subQuestion.key} label={<span className="formContent">{subQuestion.label}</span>}>
                  <Radio.Group
                    onChange={(e: RadioChangeEvent) => handleAnswerChange(subQuestion.key, e.target.value)}
                    value={answers[subQuestion.key]}
                  >
                    {subQuestion.options.map(option => (
                      <Radio.Button key={option} value={option} className='formRadioButtons'>{option}</Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              ))}
            </>
          )}
          <Button type="primary" onClick={handleSubmit} className="submitButton">Submit</Button>
        </Form>
        {loading && <Spin />}
      </Card>
      {showResult && (
        <Result
          status="success"
          title="Integration Guide Generation Completed"
          subTitle={<span style={{fontSize: '20px'}}>Please find your custom integration guide at this <Typography.Link href={url} target="_blank" style={{fontSize: '20px'}}>website</Typography.Link></span>}
        />
      )}
    </div>
  );
};

export default IntegrifyForm;