import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';

const Welcome = () => {
  return (
    <>
      <Title>This website will help you find the tests you need to run before going live.</Title>

      <Card
        border="warning"
        style={{ fontSize: '20px', justifyContent: 'center', width: '45%', margin: 'auto' }}
      >
        <Card.Body>
          <Card.Text>1. Select the flows which apply to your integration.</Card.Text>
          <Card.Text>2. Download test cases.</Card.Text>
          <Card.Text>3. Test the cases locally one-by-one.</Card.Text>
        </Card.Body>
      </Card>

      <Title>Once done, voila! You can go live!</Title>
    </>
  );
};

const Title = styled.div`
  margin: 30px 70px 30px 70px;
  font-size: 20px;
  color: #152939;
  text-align: center;
`;

export default Welcome;
