import React from "react";
import HomeCard from "../Home/HomeCard";
import "../FAQ.css";

const FaqHome = () => {
  return (
    <div className="home-class">
      <h4 className="content-tile-class mt-3">Welcome to Amazon Pay FAQ</h4>
      <div
        style={{ flex: 1, height: "2px", backgroundColor: "lightpink" }}
        className="mt-5"
      />
      <h5 className="content-underline-class mt-5">
        Amazon Pay FAQ is a tool that compiles commonly asked customer questions
        for quick reference.
      </h5>
      <HomeCard />
    </div>
  );
};

export default FaqHome;
