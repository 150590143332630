import React from 'react';
import Welcome from './Welcome';
import UATForm from './UATForm';

const UATApp = () => {
  return (
    <>
      <Welcome />
      <UATForm />
    </>
  );
};

export default UATApp;
