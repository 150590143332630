import React from 'react';
import styled from 'styled-components';

const Footer = ({ bgColor = '#152939', textColor = '#f8f9fa'}) => {
  return (
    <FooterContainer
      bgColor={bgColor}
      textColor={textColor}
    >&copy;{new Date().getFullYear()} Amazon Payments, Inc. or its affiliates</FooterContainer>
  );
};

const FooterContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  font-size: 15px;
  text-align: center;
  padding: 15px 0;
  width: 100%;
  position: relative;
  bottom: 0;
`;

export default Footer;
