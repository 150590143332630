import React, { useEffect, useState } from "react";
import { Layout, theme, Menu, Breadcrumb } from "antd";
import { useParams } from "react-router-dom";
import { useAPI } from "../APIContext";
import FaqDisplay from "src/components/AmazonPayFAQ/FAQDisplay/FaqDisplay";
import { ProgressSpinner } from "primereact/progressspinner";
import { FaqElement } from "../Types/FAQData";
import Error404 from "src/components/Error404";
import "../FAQ.css";
import Sidebar from "src/components/AmazonPayFAQ/Sidebar/Sidebar";
import FaqBreadCrumb from "src/components/AmazonPayFAQ/FAQDisplay/FaqBreadCrumb";
import { FAQ_BASEURL } from "src/appConstants";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;
const REGISTRATON = "registration";
const INTEGRATION = "integration";
const OPERATION = "operation";
const SELLERCENTRAL = "sellercentral";
const BUYERS = "buyers";
const SP = "sp";

function FaqLayout(props) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { category } = useParams();
  const contextResult = useAPI();
  if (contextResult) {
    const {
      registrationFAQ,
      integrationFAQ,
      operationFAQ,
      sellerCentralFAQ,
      buyerFAQ,
      spFAQ,
      totalFAQ,
    } = contextResult;
    const [FAQ, setFAQ] = useState<FaqElement[] | undefined>(undefined);
    const [title, setTitle] = useState<any>();
    const anchor = window.location.hash.split("#")[1];
    const [breadCrumbElement, setBreadCrumbElement] = useState<String[]>();
    const [nonSpPage, setNonSpPage] = useState(
      window.location.pathname != "/technical-faq/" + SP,
    );

    useEffect(() => {
      if (anchor && totalFAQ && spFAQ) {
        setFAQ(
          (nonSpPage ? totalFAQ : spFAQ)
            .filter((faq) => faq.QuestionId.toString() === anchor)
            .map((faq) => {
              return faq;
            }),
        );
        setBreadCrumbElement([anchor]);
        setTitle("");
      } else {
        switch (category) {
          case REGISTRATON: {
            setFAQ(registrationFAQ);
            setTitle(REGISTRATON);
            setBreadCrumbElement([REGISTRATON]);
            break;
          }
          case INTEGRATION: {
            setFAQ(integrationFAQ);
            setTitle(INTEGRATION);
            setBreadCrumbElement([INTEGRATION]);
            break;
          }
          case OPERATION: {
            setFAQ(operationFAQ);
            setTitle(OPERATION);
            setBreadCrumbElement([OPERATION]);
            break;
          }
          case SELLERCENTRAL: {
            setFAQ(sellerCentralFAQ);
            setTitle(SELLERCENTRAL);
            setBreadCrumbElement([SELLERCENTRAL]);
            break;
          }
          case BUYERS: {
            setFAQ(buyerFAQ);
            setTitle(BUYERS);
            setBreadCrumbElement([BUYERS]);
            break;
          }
          case SP: {
            setFAQ(spFAQ);
            setTitle("SP Self-Service Onboarding");
            setBreadCrumbElement([SP]);
            break;
          }
          default: {
            if (props.searchResult) {
              setFAQ(props.searchResult);
              setTitle(
                props.searchResult.length > 0
                  ? "Search Result"
                  : "No result found",
              );
              setBreadCrumbElement(["Search"]);
              setNonSpPage(props.selectSearchCategory != SP);
            } else {
              alert("Something went wrong");
              window.location.replace(FAQ_BASEURL);
            }
          }
        }
      }
    }, [props.searchResult]);

    if (FAQ) {
      const sideBarSelectedCategory = (
        selectedFAQ,
        title,
        breadCrumbElement,
      ) => {
        setFAQ(selectedFAQ);
        setTitle(title);
        setBreadCrumbElement(breadCrumbElement);
      };
      return (
        <div className="Faq-layout">
          <Layout>
            <Layout>
              <Sidebar
                nonSpPage={nonSpPage}
                sideBarSelectedCategory={sideBarSelectedCategory}
              />
              <Layout className="Layout-c">
                <FaqBreadCrumb breadCrumbElement={breadCrumbElement} />
                <Content
                  className="content-layout"
                  style={{
                    background: colorBgContainer,
                  }}
                >
                  <div className="FaqDisplay-title">
                    <h2>{title.replace(/\b\w/g, (x) => x.toUpperCase())} </h2>
                  </div>
                  <FaqDisplay FAQ={FAQ} nonSpPage={nonSpPage} />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </div>
      );
    }
    return (
      <div className="card flex justify-content-center">
        <ProgressSpinner />
        <p>Loading</p>
      </div>
    );
  } else {
    return <Error404 linkTo="/technical-faq"/>;
  }
}
export default FaqLayout;
