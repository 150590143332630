import React, { useState } from "react";
import { FaqElement } from "src/components/AmazonPayFAQ/Types/FAQData";
import { APIContextProvider } from "src/components/AmazonPayFAQ/APIContext";
import FaqNavigationBar from "src/components/AmazonPayFAQ/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import FaqLayout from "src/components/AmazonPayFAQ/Layout/FaqLayout";
import Footer from "src/components/Footer";
import FaqHome from "src/components/AmazonPayFAQ/Home/Home";

const FaqRouter = () => {
  const [searchFaq, setSearchFaq] = useState<FaqElement[] | null>(null);
  const [selectSearchCategory, setSelectSearchCategory] = useState(null);
  const searchFaqResult = (
    searchFaq: FaqElement[] | null,
    selectSearchCategory,
  ) => {
    setSearchFaq(searchFaq);
    setSelectSearchCategory(selectSearchCategory);
  };
  document.title = "AmazonPay FAQ";
  if (searchFaq) {
    return (
      <APIContextProvider>
        <FaqNavigationBar searchFaqResult={searchFaqResult} />
        <Routes>
          <Route
            path="*"
            element={
              <>
                <FaqLayout
                  searchResult={searchFaq}
                  selectSearchCategory={selectSearchCategory}
                />
                <Footer />
              </>
            }
          ></Route>
        </Routes>
      </APIContextProvider>
    );
  } else {
    return (
      <APIContextProvider>
        <FaqNavigationBar searchFaqResult={searchFaqResult} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <FaqHome />
                <Footer />
              </>
            }
          />
          <Route
            path="/:category"
            element={
              <>
                <FaqLayout />
                <Footer />
              </>
            }
          />
        </Routes>
      </APIContextProvider>
    );
  }
};

export default FaqRouter;
