import React from "react";
import "./FAQ.css";
import swal from "@sweetalert/with-react";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { Tooltip } from "@material-ui/core";
const FaqFeedback = () => {
  function feedBackFormLoader() {
    swal(
      <iframe
        src="https://amazon.au1.qualtrics.com/jfe/form/SV_4PK1Q47hoYxGxFk"
        width="100%"
        height="500"
      ></iframe>,
      {
        button: false,
        width: "100%",
        height: "500",
      },
    );
  }
  return (
    <Tooltip title="Feedback">
      <FeedbackIcon
        fontSize="large"
        className="Faq-Feedback-Icon"
        onClick={feedBackFormLoader}
      />
    </Tooltip>
  );
};

export default FaqFeedback;
