import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import UATApp from "./UATApp";
import Error404 from "./Error404";
import UATHeader from "src/components/UATHeader";
import Footer from "src/components/Footer";
import FaqRouter from "src/components/AmazonPayFAQ/FAQRouter";
import IntegrifyRouter from "src/components/IntegrifyTool/Router";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <UATHeader />
              <UATApp />
              <Footer bgColor="#f8f9fa" textColor="#152939"/>
            </>
          }
        />
        <Route
          path="/uat-suite"
          element={
            <>
              <UATHeader />
              <UATApp />
              <Footer bgColor="#f8f9fa" textColor="#152939"/>
            </>
          }
        />
        <Route
          path="/technical-faq/*"
          element={
            <>
              <FaqRouter />
            </>
          }
        />
        <Route
          path="/integrify/*"
          element={
            <>
              <IntegrifyRouter />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <UATHeader />
              <Error404 linkTo={"/"}/>
              <Footer bgColor="#f8f9fa" textColor="#152939"/>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
