import React, { useState } from "react";
import IntegrifyHeader from "src/components/IntegrifyTool/Header";
import Footer from "src/components/Footer";
import IntegrifyIntro from "src/components/IntegrifyTool/Intro";
import IntegrifyForm from "src/components/IntegrifyTool/Form";
import Error404 from "src/components/Error404";
import { Route, Routes } from "react-router-dom";

const IntegrifyRouter = () => {
  document.title = "Amazon Pay Integrify";
    return(
    <Routes>
    <Route
      path="/"
      element={
        <>
          <IntegrifyHeader />
          <IntegrifyIntro/>
          <IntegrifyForm />
          <Footer />
        </>
      }
    ></Route>
    <Route
          path="/*"
          element={
            <>
              <IntegrifyHeader />
              <Error404 linkTo="/integrify"/>
              <Footer />
            </>
          }
    />
  </Routes>
    )
};

export default IntegrifyRouter;