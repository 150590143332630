import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Row } from "react-bootstrap";
import { FAQ_BASEURL } from "src/appConstants";

const HomeCard = () => {
  const homeCardData = [
    {
      categoryTitle: "Questions About Application/Examination",
      categoryDescription:
        "FAQ About Application/Screening Before Using Amazon Pay.",
      link: FAQ_BASEURL + "/registration",
    },
    {
      categoryTitle: "Questions About Development and Implementation",
      categoryDescription:
        "This is a FAQ About the Development and Implementation of Amazon Pay CV2.",
      link: FAQ_BASEURL + "/integration",
    },
    {
      categoryTitle: "Frequently Asked Questions During Operation",
      categoryDescription:
        "This is a FAQ About Operations After Introducing Amazon Pay.",
      link: FAQ_BASEURL + "/operation",
    },
    {
      categoryTitle: "How to Use Seller Central",
      categoryDescription: "FAQ About Seller Central.",
      link: FAQ_BASEURL + "/sellercentral",
    },
    {
      categoryTitle: "Buyer help",
      categoryDescription: "FAQ for Amazon Pay Buyers.",
      link: FAQ_BASEURL + "/buyers",
    },
    {
      categoryTitle: "SP Self-Service Onboarding",
      categoryDescription:
        "FAQ for Solution Providers for Self-Service Onboarding",
      link: FAQ_BASEURL + "/sp",
    },
  ];
  return (
    <Row xs={3} md={2} className="g-4 mt-5 me-2 ms-2">
      {homeCardData.map((data) => {
        return (
          <Col>
            <Card border="info">
              <Card.Body>
                <Card.Title className="fw-bolder fs-5 text-success">
                  {data.categoryTitle}
                </Card.Title>
                <Card.Text className="fst-italic text-muted">
                  {data.categoryDescription}
                </Card.Text>
                <Card.Link href={data.link}>
                  <button type="button" className="btn btn-outline-dark">
                    View Details
                  </button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default HomeCard;
