import React from 'react';
export const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

export const customStyles = {
  tableWrapper: {
    style: {
      width: '100%',
      tableLayout: 'fixed',
    },
  },
};

export const columns = [
  {
    name: 'Test ID',
    selector: 'TestID',
    sortable: true,
  },
  {
    name: 'Section',
    selector: 'Section',
    sortable: true,
  },
  {
    name: 'Category',
    selector: 'Category',
    sortable: true,
  },
  {
    name: 'Environment',
    selector: 'Environment',
    sortable: true,
  },
  {
    name: 'Goods Type',
    selector: 'GoodsType',
    sortable: true,
  },
  {
    name: 'Payment Type',
    selector: 'PaymentType',
    sortable: true,
  },
  {
    name: 'Region',
    selector: 'Region',
    sortable: true,
  },
  {
    name: 'Device Type',
    selector: 'DeviceType',
    sortable: true,
  },
  {
    name: 'Test Case',
    selector: 'TestCase',
    sortable: true,
  },
  {
    name: 'Criteria',
    selector: 'Criteria',
    sortable: true,
  },
];
