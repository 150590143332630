import React from 'react';
import { Navbar, Container, Image } from 'react-bootstrap';
import { AMAZONPAY_LOGO_URL } from "src/appConstants";

const IntegrifyHeader = () => {
  return (
    <>
      <Navbar style={{ background: "#152939" }} variant="dark">
        <Container fluid>
          <Navbar.Brand style={{ fontSize: '35px', textAlign: 'center'}}>
            <img src={ AMAZONPAY_LOGO_URL } height="35" alt="" loading="lazy" />
            &nbsp;INTEGRIFY
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
};

export default IntegrifyHeader;
