import React from "react";
import { Breadcrumb } from "antd";
import "../FAQ.css";
const FaqBreadCrumb = (props) => {
  return (
    <Breadcrumb className="Faq-BreadCrumb">
      {props.breadCrumbElement.map((item) => {
        return (
          <Breadcrumb.Item className="text-success fs-6" key={item}>
            {item.replace(/\b\w/g, (x) => x.toUpperCase())}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
export default FaqBreadCrumb;
