import React from 'react';
import { Navbar, Container, Image } from 'react-bootstrap';
import logo from 'src/assets/logo-amazonpay.1fae0f1e.png';

const UATHeader = () => {
  return (
    <>
      <Navbar bg="light" style={{ borderBottom: 'solid 2px #ffc107' }}>
        <Container>
          <Navbar.Brand style={{ fontSize: '27px', textAlign: 'center' }}>
            <Image
              alt="Amazon Pay Logo"
              src={logo}
              width="150"
              height="30"
              className="d-inline-block align-top"
            />
            &nbsp; UAT Integration Test Suite
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
};

export default UATHeader;
