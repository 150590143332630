import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../FAQ.css";
import Fuse from "fuse.js";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { useAPI } from "../APIContext";
import Error404 from "src/components/Error404";
import { AMAZONPAY_LOGO_URL } from "src/appConstants";
import { FAQ_BASEURL } from "src/appConstants";
import FaqFeedback from "src/components/AmazonPayFAQ/FAQFeedback";
import { Tooltip } from "@material-ui/core";

const FaqNavigationBar = ({ searchFaqResult }) => {
  const contextResult = useAPI();
  if (contextResult) {
    const { spFAQ, totalFAQ } = contextResult;
    const nonSpPage = window.location.pathname != "/technical-faq/" + "sp";
    const [FAQ, setFAQ] = useState(nonSpPage ? totalFAQ : spFAQ);
    const category = [
      { name: "Merchant", value: "merchant" },
      { name: "SP", value: "sp" },
    ];
    const [selectSearchCategory, setSelectSearchCategory] = useState(
      nonSpPage ? category[0].value : category[1].value,
    );
    const FUSE_OPTIONS = {
      isCaseSensitive: false,
      includeScore: true, // return the score alongside the match (lower is better)
      includeMatches: true, // return the match indices so that the matched portions can be highlighted
      findAllMatches: true, // if multiple matches exist, then that object should have a better score
      minMatchCharLength: 1,
      ignoreLocation: true, // it should not matter where in the search string the pattern appears
      ignoreFieldNorm: true,
      threshold: 0.5, // allow fuzzy matches
      keys: [{ name: "Question", weight: 4 }],
    };

    const fuse = new Fuse(FAQ, FUSE_OPTIONS);
    const handleSearch = (e) => {
      const keyword = e.target.value.trim();
      if (keyword.length > 0) {
        const searchResults = fuse.search(keyword).map((result) => result.item);
        searchFaqResult(searchResults, selectSearchCategory);
      } else {
        searchFaqResult(null, selectSearchCategory);
      }
    };
    const handleSelectSearchCategory = (e) => {
      setSelectSearchCategory(e.currentTarget.value);
      setFAQ(e.currentTarget.value === category[0].value ? totalFAQ : spFAQ);
    };
    return (
      <Navbar style={{ background: "#152939" }} variant="dark">
        <Container fluid>
          <Navbar.Brand href={nonSpPage ? FAQ_BASEURL : FAQ_BASEURL + "/sp"}>
            <img src={AMAZONPAY_LOGO_URL} height="35" alt="" loading="lazy" />
            &nbsp;FAQ
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href={FAQ_BASEURL}>
                {nonSpPage ? "Home" : "Merchant FAQ"}
              </Nav.Link>
            </Nav>
            <FaqFeedback />
            <Form
              className="d-flex"
              id="FORM_ID"
              style={{ marginLeft: "20px" }}
            >
              <Form.Control
                type="search"
                placeholder="Please enter a keyword"
                className="me-10"
                aria-label="Search"
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                onChange={handleSearch}
              />
              {window.location.pathname == "/technical-faq" && (
                <ButtonGroup style={{ marginLeft: "10px" }}>
                  {category.map((item, idx) => (
                    <Tooltip title={item.name + " FAQ Search"}>
                      <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="outline-success"
                        name="radio"
                        size="sm"
                        value={item.value}
                        checked={selectSearchCategory === item.value}
                        onChange={handleSelectSearchCategory}
                      >
                        {item.name}
                      </ToggleButton>
                    </Tooltip>
                  ))}
                </ButtonGroup>
              )}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  } else {
    return <Error404 linkTo="/technical-faq"/>;
  }
};

export default FaqNavigationBar;
