import React from 'react';
import { Card, Steps } from 'antd';
import './Integrify.css';
import { INTEGRIFY_TOOL_STEPS } from 'src/appConstants';

const IntegrifyIntro = () => {
  return (
    <>
      <Card 
        hoverable
        title={<span className="introTitle">Welcome to Amazon Pay Integrify</span>}
        className='introCard'
      >
        <p className='introContent'>
          Integrify is a smart Integration Guide Generation tool for developers integrating with Amazon Pay.
        </p>
      </Card>
      <Card 
        title={<span className="cardTitle">Generate a simple and direct integration guide for the features you need to implement in three steps.</span>}
        className='stepsCard'
      >
        <Steps
          className="steps"
          direction="vertical"
          items={INTEGRIFY_TOOL_STEPS}
        />
      </Card>
    </>
  );
};

export default IntegrifyIntro;